import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../auth/authentication.service';
import { FormGroup } from '@angular/forms';
import { MpService } from '../mp/mp.service';
import { Router } from '@angular/router';
import {
  faChevronRight,
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-steps',
  templateUrl: './steps.component.html',
  styleUrls: ['./steps.component.scss'],
})
export class StepsComponent implements OnInit {
  loading = false;
  step: string;
  stepsCount = 6;
  currentStep = 1;
  //voy poniendo los steps para poder moverme mas facil
  steps: string[] = [];

  productor = false;

  clients: any = [];
  clientsPages: any = [];
  filteredClients: any = [];
  products: any = [];
  brands: any = [];
  documentTypes: any = [];
  models: any = [];
  years: any = [];
  branches: any = [];
  states: any = [];
  versions: any = [];
  types: any = [];
  genders: any = [];

  frenteFile = null;
  dorsoFile = null;

  branchName = 'auto';
  modelName: string;
  yearName: string;
  versionName: string;
  productName: string;
  brandName: string;
  showData: any;
  typeIdSelect: any;
  faChevronRight = faChevronRight;
  faChevronLeft = faChevronLeft;

  form: FormGroup;
  saving: boolean;

  branch: any;
  type: any;
  product: any;

  constructor(
    public authenticationService: AuthenticationService,
    public mpService: MpService,
    private _router: Router,
  ) {

    this.redirectWeb();

    this.authenticationService
      .loginStatusChange()
      .subscribe(() => this.redirectWeb());
  }

  redirectWeb(){
    this.productor = this.authenticationService.getUser()?.role == 'producer';
    this.loading = true;
    if (!this.productor && this.authenticationService.isLoggedIn){
      this._router.navigate(['/mp_history']);
      return;
    }
      
    if (this.productor) {
      this._router.navigate(['/policies']);
    }else{
      this._router.navigate(['/branch']);
    }
  }

  ngOnInit(): void {}
}

import { StepsModule } from './main/steps/steps.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';
import { AngularToastifyModule } from 'angular-toastify';
import { TokenInterceptor } from './helpers/token.interceptor';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { DatePipe } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { ShoppingHistoryModule } from './main/shopping-history/shopping-history.module';
import { AuthenticationModule } from './auth/authentication.module';
import { MpModule } from './main/mp/mp.module';

export function playerFactory() {
  return player;
}

const appRoutes: Routes = [
  // {
  //   path: '',
  //   redirectTo: 'steps',
  //   pathMatch: 'full',
  // },
  {
    path: 'auth',
    loadChildren: () =>
      import('./auth/authentication.module').then((m) => m.AuthenticationModule),
  },
  {
    path: 'mp',
    loadChildren: () =>
      import('./main/mp/mp.module').then((m) => m.MpModule),
  },
  // {
  //   path: 'mp_history',
  //   loadChildren: () =>
  //     import('./main/shopping-history/shopping-history.module').then((m) => m.ShoppingHistoryModule),
  // },
];

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
  ],
  imports: [
    BrowserModule,
    CdkStepperModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' }),
    HttpClientModule,
    FormsModule,
    AngularToastifyModule,
    LottieModule.forRoot({ player: playerFactory }),
    StepsModule,
    ShoppingHistoryModule,
    AuthenticationModule,
    MpModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    DatePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
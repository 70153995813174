<div class="loading-modal spinner-lg" *ngIf="loading"></div>


    <div class="col-md-8 offset-md-2">
      <div class="header">
        <h2>Bienvenido!</h2>
        <app-dots [steps]="stepsCount" [active]="currentStep"></app-dots>
      </div>

      <div class="row justify-content-center">
        <button type="button" class="btn btn-primary m-3" (click)="goToHistory()">
          Pólizas Vendidas
        </button>
        <button
          type="button"
          class="btn btn-primary m-3"
          (click)="newClientOnClick()"
        >
          Nueva Venta <fa-icon [icon]="faChevronRight"></fa-icon>
        </button>
      </div>    
      
      <div class="form-group">
        <label class="clients-title">Clientes:</label>
        <div class="row offset-md-3"></div>
        <div class="card clients-table">
          <input
            type="text"
            class="form-control"
            placeholder="Buscar cliente..."
            (input)="searchClientOnChange($event)"
          />
          <div class="no-result" *ngIf="filteredClients == 0">
            <p>No hay resultados</p>
          </div>
          <div class="table" *ngIf="filteredClients != 0">
            <table>
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th>DNI</th>
                  <th>Descargar</th>
                  <th>Nueva</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="client-register"
                  *ngFor="let client of filteredClients"
                >
                  <td>{{ client.lastName + ", " + client.firstName }}</td>
                  <td>{{ client.documentNumber }}</td>
                  <td>
                    <button
                      type="button"
                      (click)="downloadPolicy(client)"
                      class="btn"
                    >
                    ›
                    </button>
                  </td>
                  <td>
                    <button
                      type="button"
                      (click)="selectClient(client)"
                      class="btn"
                    >
                      ›
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="row justify-content-center align-items-center">
            <label>Página</label>
            <select class="form-select" (change)="clientsPageOnChange($event)">
              <option *ngFor="let page of clientsPages">{{ page }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>


<div class="loading-modal spinner-lg" *ngIf="loading"></div>

<div class="container narrow">
  <div class="header">
    <h2>Elegí la mejor opción para tu {{ branchName }}</h2>
    <app-dots [steps]="stepsCount" [active]="currentStep"></app-dots>
  </div>

  <div class="form-group buttons-list">
    <button
      type="button"
      class="btn btn-product"
      style="flex: 100%"
      *ngFor="let product of this.products"
      (click)="selectProduct(product)"
    >
      {{ product.short_name }} -
      <span [ngClass]="{ base_price: product.basePrice > 0 }"
        >${{ product.price }}</span
      >
      <span *ngIf="product.basePrice > 0"> ${{ product.basePrice }}</span>
    </button>
  </div>

  <button type="button" class="btn btn-secondary" (click)="prevStep()">
    <fa-icon [icon]="faChevronLeft"></fa-icon> Anterior
  </button>
</div>

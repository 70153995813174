import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from '../../shared/validators/custom-validators';
import { AuthenticationService } from '../authentication.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-format-password',
  templateUrl: './format-password.component.html',
  styleUrls: ['./format-password.component.scss']
})
export class FormatPasswordComponent implements OnInit {

  error: string;
  resetLoading:false;
  resetPasswordForm:FormGroup;
  

  constructor(public authenticationService: AuthenticationService,private location: Location,) { }

  ngOnInit(): void {
    this.resetPasswordForm = new FormGroup(
      {
      current_password: new FormControl('',[Validators.required,Validators.minLength(6)]),
      password: new FormControl('',[Validators.required,Validators.minLength(6)]),
      password_confirmation: new FormControl('',[Validators.required,Validators.minLength(6)])
      },
      [CustomValidators.MatchValidator('password', 'password_confirmation')]
      )
  }
   
  change(): void {
    
    this.authenticationService.changePassword(this.resetPasswordForm.value,err => {
      this.resetLoading = false;
      this.error = err;
    });
 
  }

  clearErrorMessage(): void {
    const e = (message) => (this.error = message);
    e(null);
  }

  cancel(): void {
    this.location.back(); // <-- go back to previous location on cancel

  }

}

<div class="container narrow">

  <form [formGroup]="resetPasswordForm">

    <div class="header">
      <h2>Reseteo de contraseña:</h2>
    </div>

    <div class="form-group">     
      <label>Nueva contraseña:</label>
      <input type="password" class="form-control" formControlName="password" maxlength="12"
        (input)="this.clearErrorMessage()" />
      <br>
      <div
      *ngIf="resetPasswordForm.controls['password'].invalid && (resetPasswordForm.controls['password'].dirty || resetPasswordForm.controls['password'].touched)"
      class="alert">
      <div *ngIf="resetPasswordForm.controls['password'].errors.required">
        Debe ingresar una contraseña
      </div>
      <div *ngIf="resetPasswordForm.controls['password'].hasError('minlength')">
        Debe tener al menos 6 caracteres.
      </div>
      <br>
    </div>

      <label>Repita la nueva contraseña:</label>
      <input type="password" class="form-control" formControlName="password_confirmation" maxlength="12"
        (input)="this.clearErrorMessage()" />
    </div>
    <br>

    <div
    *ngIf="resetPasswordForm.controls['password_confirmation'].invalid && (resetPasswordForm.controls['password_confirmation'].dirty || resetPasswordForm.controls['password_confirmation'].touched)"
    class="alert">
    <div *ngIf="resetPasswordForm.controls['password_confirmation'].hasError('minlength')">
      Debe tener al menos 6 caracteres.
    </div>
    <div *ngIf="resetPasswordForm.controls['password_confirmation'].hasError('mismach')">
     Las contraseñas no coinciden
    </div>
    <br>
  </div>
    <div class="error" *ngIf="!!this.error">{{ this.error }}</div>

    <br>

    <div class="buttons-list">
      <button type="button" class="btn btn-primary" [disabled]="!resetPasswordForm.valid" (click)="change()">
        Resetear
      </button>

      <button type="button" class="btn btn-primary" (click)="cancel()">
        Cancelar
      </button>
    </div>

  </form>

</div>
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from '../../shared/validators/custom-validators';
import { Location } from '@angular/common';

@Component({
  selector: 'app-delete-account',
  templateUrl: './delete-account.component.html',
  styleUrls: ['./delete-account.component.scss']
})
export class DeleteAccountComponent implements OnInit {
  firstName: string = '';
  lastName: string = '';
  email: string = '';
  showModal: boolean = false;

  ngOnInit(): void {
  }

  onSubmit() {
    const requestData = {
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email
    };

    this.showModal = true;
  }

  closeModal() {
    this.showModal = false;
  }
}

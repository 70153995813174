import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AnimationOptions } from 'ngx-lottie';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from 'src/app/core/components/base-component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent extends BaseComponent implements OnInit {

  step:String = "";
  successLottie: AnimationOptions = {
    path: '/assets/success.json',
    loop: false,
  };
  promise: Promise<any> | undefined;
  promise2: Promise<any> | undefined;
  responseDataId: String;

  constructor(private _router: Router,
    private _activeRoute: ActivatedRoute,) {
    super();
  }

  ngOnInit(): void {
    this._activeRoute.params
    .pipe(takeUntil(this.componentDestroyed))
    .subscribe((params: Params) => {
      this.responseDataId = params["responseDataId"];

      this.loadScipts(this.responseDataId);
    });
  }

    loadScipts(preferenceId) {
      this.promise = new Promise((resolve) => {
        resolve(this.loadSDK());
      }).then(() => {
        setTimeout(() => {
          // configuraciones aquí
          this.promise2 = new Promise((resolve) => {
            this.step = 'payment';
            resolve(this.putScript(preferenceId));
          }).then(() => {
            setTimeout(() => {
              // configuraciones aquí
              console.log('run Script.');
            }, 2000);
          });
        }, 2000);
      });
    }
  
    loadSDK() {
      let node = document.createElement('script');
      node.src = environment.mp.url;
      node.type = 'text/javascript';
      node.async = true;
      document.getElementsByTagName('head')[0].appendChild(node);
      return true;
    }
  
    putScript(preferenceId) {
      let node2 = document.createElement('script');
      let data =
        'const mp = new MercadoPago("' +
        environment.mp.public_key +
        "\",{locale: 'es-AR'});";
      data+="mp.checkout({preference: {id:'"+preferenceId+"'},autoOpen:true, });";
      node2.text = data;
      node2.type = 'text/javascript';
      node2.async = true;
      document.getElementsByTagName('head')[0].appendChild(node2); //*/
      return true;
    }

  reset() {
    this._router.navigate(['../../../../'])
  }

}
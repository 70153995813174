import { BaseComponent } from 'src/app/core/components/base-component';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/auth/authentication.service';
import { DataService } from 'src/app/shared/services/data.service';
import { forkJoin } from 'rxjs';
import {
  faChevronRight,
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';
import {Location} from '@angular/common';

@Component({
  selector: 'app-branch-step',
  templateUrl: './branch-step.component.html',
  styleUrls: ['./../steps.component.scss']
})
export class BranchStepComponent extends BaseComponent implements OnInit  {
  loading = false;
  branches: any = [];
  productor = false;
  stepsCount = 6;
  currentStep = 1;
  faChevronLeft = faChevronLeft;
  client: number = 0;
  paramsObject: any;

  constructor(
    private service: DataService,
    public authenticationService: AuthenticationService,
    private _router: Router,
    private _activeRoute: ActivatedRoute,
    private _location: Location
  ) {
    super();
    this.loadData();

    this.authenticationService
      .loginStatusChange()
      .subscribe(() => this.loadData());
  }

  ngOnInit(): void {
    this._activeRoute.queryParamMap.subscribe((params) => {
      this.paramsObject = { ...params.keys, ...params };

      this.client = this.paramsObject.params.client ?? 0;
    });
  }

  loadData() {
    if (this.loading) return;

    this.productor = this.authenticationService.getUser()?.role == 'producer';
    this.loading = true;

    forkJoin(
      this.service.getBranches(),
    ).subscribe(
      ([
        branchesRes,
      ]: any[]) => {
        this.branches = branchesRes.data;
        this.loading = false;
      }
    );
  }

  selectBranch(branch) {
    this._router.navigate([`/branch/${branch.id}/type`], {queryParams:{'client': this.client}});
  }


  prevStep() {
    this._location.back();
  }

}

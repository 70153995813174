import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../auth/authentication.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  isShowing: boolean = false;
  constructor(
    public authenticationService: AuthenticationService,
    private router: Router
  ) {}

  ngOnInit(): void {}

  logout(): void {
    this.authenticationService.logout();
    localStorage.clear();
  }

  back() {
    console.log(this.router);
    if (this.router.url == '/')
      window.location.href = 'https://seguroporhoy.com';
    else this.router.navigate(['/']);
  }

  changePass() {
    this.router.navigate(['auth/mp_change_pass']);
  }
}

<header>
  <div class="container">
    <!-- <a href="#" (click)="back()">
      <img src="/assets/left-arrow.svg" class="left-arrow">
    </a> -->
    <a href="https://seguroporhoy.com" class="logo">
      <img src="/assets/logo.png">
    </a>
    <div class="fill"></div>
    <nav>
      <ul>
        <li *ngIf="this.authenticationService.isLoggedIn" class="current-user">
          {{this.authenticationService.getUser()?.user}}
          {{this.authenticationService.isProducer ? '(Productor)' : ''}}
        </li>
        <!-- <li [ngClass]="{ 'd-none': this.authenticationService.isLoggedIn }">
          <a routerLink="/auth">Acceder</a>
        </li> -->
        <li [ngClass]="{ 'd-none': !this.authenticationService.isLoggedIn }">
            <a style="cursor: pointer;" (click)="changePass()">Cambiar Contraseña&nbsp;&nbsp;&nbsp;| </a>
          </li>
        <li [ngClass]="{ 'd-none': !this.authenticationService.isLoggedIn }">
          <a style="cursor: pointer;" (click)="logout()">Salir</a>
        </li>
      </ul>
    </nav>
  </div>
</header>

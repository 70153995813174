import { BaseComponent } from 'src/app/core/components/base-component';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/auth/authentication.service';
import { DataService } from 'src/app/shared/services/data.service';
import { forkJoin } from 'rxjs';
import {
  faChevronRight,
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';
import { remove } from 'remove-accents';
import { OrderService } from 'src/app/shared/services/order.service';
import { FormService } from 'src/app/shared/services/form.service';

const clientsPerPage = 10;

@Component({
  selector: 'app-policies-step',
  templateUrl: './policies-step.component.html',
  styleUrls: ['./policies.component.scss'],
})
export class PoliciesStepComponent extends BaseComponent implements OnInit {
  loading = false;
  branches: any = [];
  productor = false;
  stepsCount = 6;
  currentStep = 1;
  faChevronRight = faChevronRight;
  faChevronLeft = faChevronLeft;
  filteredClients: any = [];
  clients: any = [];
  clientsPages: any = [];

  constructor(
    private service: DataService,
    public authenticationService: AuthenticationService,
    private _router: Router,
    private _activeRoute: ActivatedRoute,
    private orderService: OrderService,
    private formService: FormService
  ) {
    super();
    this.loadData();

    this.authenticationService
      .loginStatusChange()
      .subscribe(() => this.loadData());
  }

  loadData() {
    if (this.loading) return;

    this.productor = this.authenticationService.getUser()?.role == 'producer';
    this.loading = true;

    forkJoin(this.service.getBranches()).subscribe(([branchesRes]: any[]) => {
      this.branches = branchesRes.data;

      if (this.productor) {
        this.loading = true;
        this.service
          .getProducer(this.authenticationService.getProducerId())
          .subscribe((res: any) => {
            this.clients = res.data.clients;
            this.updateClientsTable();
            this.loading = false;
          });
      } else this.loading = false;
    });
  }

  goToHistory() {
    this._router.navigate(['/mp_history']);
  }

  newClientOnClick() {
    this._router.navigate(['/branch']);
  }

  searchClientOnChange(ev) {
    this.updateClientsTable({ search: ev.target.value });
  }

  updateClientsTable({ search = null, page = 1 } = {}) {
    let filtered = [],
      currentPage = [];

    let matches = (client) =>
      client.documentNumber.replace(/[^0-9]/g, '') ==
        search.replace(/[^0-9]/g, '') ||
      remove(client.firstName).match(new RegExp(remove(search), 'i')) ||
      remove(client.lastName).match(new RegExp(remove(search), 'i')) ||
      remove(client.documentNumber).match(new RegExp(remove(search), 'i'));

    for (let client of this.clients) {
      if (!search || matches(client)) filtered.push(client);
    }

    let offset = (page - 1) * clientsPerPage;

    for (
      let i = offset;
      i < offset + clientsPerPage && i < filtered.length;
      i++
    )
      currentPage.push(filtered[i]);
    this.filteredClients = currentPage;

    let pageCount = Math.ceil(filtered.length / clientsPerPage),
      arr = [];
    for (let i = 1; i <= pageCount; i++) arr.push(i);
    this.clientsPages = arr;
  }

  downloadPolicy(client): void {
    this.loading = true;

    this.orderService
      .downloadPolicy(client.clientId)
      .subscribe((response: any) => {
        if (response.data != null) {
          this.downloadFile(response.data);
        }

        this.loading = false;
      });
      this.loading = false;
  }

  private downloadFile(data): void {
    const link = document.createElement('a');
    link.href = data.base_64;
    link.download = data.file_name;
    link.target = '_self';
    link.click();
  }

  selectClient(client) {
    this.formService.setClient(client);
    this._router.navigate(['/branch'], {queryParams:{'client': client.clientId}});
  }

  clientsPageOnChange(ev) {
    this.updateClientsTable({ page: ev.target.value });
  }
}

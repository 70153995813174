import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../../auth/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService
  ) {}

  downloadPolicy(client: any) {
    const url = `${environment.apiUrl}/download-policy/${client}`;
    return this.http.get(url);
  }

  downloadOrderPolicy(order: any) {
    const url = `${environment.apiUrl}/orders/download-order-policy/${order}`;
    return this.http.get(url);
  }

  getGarageAmount(values) {
    const url = `${environment.apiUrl}/commons/get-garage-price`;
    return this.http.post(url, values);
  }
}

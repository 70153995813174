import {NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SuccesComponent } from './succes/succes.component';
import { FailureComponent } from './failure/failure.component';
import { PendingComponent } from './pending/pending.component';
import { MpRoutingModule } from './mp-routing.module';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { PaymentComponent } from './payment/payment.component';

export function playerFactory() {
    return player;
  }

@NgModule({
    declarations: [SuccesComponent, FailureComponent, PendingComponent, PaymentComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MpRoutingModule,
        LottieModule.forRoot({ player: playerFactory })
    ]
})
export class MpModule {
}

import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {ToastService} from 'angular-toastify';
import {Observable, ReplaySubject, Subject} from 'rxjs';
import {AuthenticationClient} from './authentication.client';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  static getToken() {
      throw new Error('Method not implemented.');
  }
  constructor(
    private authenticationClient: AuthenticationClient,
    private router: Router,
    private toastService: ToastService
  ) {}

  public isLoggedIn: boolean = false;
  public isLoggedInSubject: Subject<boolean> = new ReplaySubject<boolean>(1);


  private setLogin(res) {
    let data = JSON.parse(res).data;

    localStorage.setItem("token", data.token);
    localStorage.setItem("user", JSON.stringify(data));

    this.isLoggedIn = true;
    this.isLoggedInSubject.next(true);

    return data;
  }

  public checkLogin() {

    if (localStorage.getItem("token") && localStorage.getItem("user")) {
        this.isLoggedIn = true;
        this.isLoggedInSubject.next(true);
    }

  }

  public login(values, onError): void {
  
    this.authenticationClient.login(values.email, values.password)
      .subscribe(res => {
        let data = this.setLogin(res);
      
    
        if ( data.role == 'producer') {
          this.router.navigate([ '/' ]);
        } else {
          this.router.navigate([ '/mp_history' ]);
        }
      }, res => {
        try {
          onError(JSON.parse(res.error).data.message);
        } catch (ex) {
          onError("Imposible conectar con el servidor.");
        }
      });
  }

  public register(values, onError): void {
    this.authenticationClient
      .register(values.name, values.email, values.password, values.confirmPassword)
      .subscribe(res => {
        this.setLogin(res);
        this.toastService.success("¡Registro exitoso!");
        this.router.navigate([ '/' ]);
      }, res => {
        try {
          onError(JSON.parse(res.error).data.message);
        } catch (ex) {
          onError("Imposible conectar con el servidor.");
        }
      });
  }
  
  public changePassword(values, onError): void {
     
    this.authenticationClient
      .changePassword(values.current_password, values.password, values.password_confirmation)
      .subscribe(res => {
        //console.log(res);
       // this.setLogin(res);
        this.toastService.success("Se cambio la contraseña");
        this.router.navigate([ '/' ]);
      }, res => {
        try {
          onError(JSON.parse(res.error).data.message);
        } catch (ex) {
          onError("Imposible conectar con el servidor.");
        }
      });
  }
  public logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    
    this.isLoggedIn = false;
    this.isLoggedInSubject.next(false);

    this.toastService.success("Saliste de tu cuenta.");
    this.router.navigate([ "/" ]);
  }

  public loginStatusChange(): Observable<boolean> {
    return this.isLoggedInSubject.asObservable();
  }

  public getUser(): any | null {
    return this.isLoggedIn ? JSON.parse(localStorage.getItem("user")) : null;
  }

  public getToken(): string | null {
    return this.isLoggedIn ? localStorage.getItem("token") : null;
  }

  public getProducerId(): string | null {
    if (this.isProducer) {
        return this.getUser().producer.producerId;
    }
  }

  get isProducer(): boolean {
    return (this.isLoggedIn && this.getUser().role == 'producer');
  }

  get isClient(): boolean {
    return (this.isLoggedIn && this.getUser().role == 'client');
  }

}

<div class="container">
  <h2>Solicitud de Borrado de Datos</h2>
  <p>Aquí puede solicitar el borrado de sus datos permanentemente. Si desea continuar con el proceso complete el formulario y nos contactaremos a la brevedad.</p>
  <form (ngSubmit)="onSubmit()">
    <div class="form-group">
      <label for="firstName">Nombre:</label>
      <input type="text" id="firstName" class="form-control" [(ngModel)]="firstName" name="firstName" required>
    </div>
    <div class="form-group">
      <label for="lastName">Apellido:</label>
      <input type="text" id="lastName" class="form-control" [(ngModel)]="lastName" name="lastName" required>
    </div>
    <div class="form-group">
      <label for="email">Correo Electrónico:</label>
      <input type="email" id="email" class="form-control" [(ngModel)]="email" name="email" required>
    </div>
    <button type="submit" class="btn btn-danger">Solicitar Borrado</button>
  </form>
</div>

<!-- Pop-up -->
<div class="modal" tabindex="-1" [ngClass]="{'show': showModal}" style="display: block;" *ngIf="showModal">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Solicitud Recibida</h5>
        <button type="button" class="close" (click)="closeModal()">
          <span>&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>Hemos recibido su solicitud. Nos contactaremos a la brevedad.</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeModal()">Cerrar</button>
      </div>
    </div>
  </div>
</div>

import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {catchError, map} from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class AuthenticationClient {
  constructor(private http: HttpClient) {}

  public login(username: string, password: string): Observable<any> {
    return this.http.post(
      environment.apiUrl + "/login",
      {
        email: username,
        password: password,
      },
      {responseType: "text"}).
      pipe(
        map(response => response)
      );
  }

  public register(
    name: string,
    email: string,
    password: string,
    password_confirmation: string
  ): Observable<string> {
    return this.http.post(
      environment.apiUrl + "/register",
      {
        name: name,
        email: email,
        password: password,
        password_confirmation: password_confirmation,
      },
      {responseType: "text"}
    );
  }

  public changePassword(current_password: string, password: string,password_confirmation:string ): Observable<any> {
    return this.http.post(
      environment.apiUrl + "/change-password",
      {
        current_password: current_password,
        password: password,
        password_confirmation:password_confirmation,
      },
      {responseType: "text"}).
      pipe(
        map(response => response)
      );
  }
}

import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TypeStepComponent } from './type-step/type-step.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BranchStepComponent } from './branch-step/branch-step.component';
import { ProductStepComponent } from './product-step/product-step.component';
import { FormStepComponent } from './form-step/form-step.component';
import { StepsComponent } from './steps.component';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { SharedModule } from 'src/app/shared/shared.module';
import { PoliciesStepComponent } from './policies-step/policies-step.component';
import { StepsRoutingModule } from './steps-routing.module';

export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [
    StepsComponent,
    TypeStepComponent,
    BranchStepComponent,
    ProductStepComponent,
    FormStepComponent,
    PoliciesStepComponent
  ],
  imports: [CommonModule, FontAwesomeModule, ReactiveFormsModule, NgSelectModule, FormsModule,
    ReactiveFormsModule, LottieModule.forRoot({ player: playerFactory }), SharedModule, StepsRoutingModule],
})
export class StepsModule {}

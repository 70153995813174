<div class="loading-modal spinner-lg" *ngIf="loading"></div>
<div class="centered-container">
  <div class="clients-table">
    <div class="row">
      <div class="col align-bottom">
        <h2>Pólizas Vendidas</h2>
      </div>
      <div class="col text-right " *ngIf="is_producer">
        <button  type="button" class="btn btn-secondary m-3" (click)="cancel()">Volver <fa-icon [icon]="faChevronRight"></fa-icon></button>

      </div>
      <div class="col text-right" *ngIf="!is_producer">
        <button  type="button" class="btn btn-primary m-1" (click)="goToHome()">Volve a comprar! <fa-icon [icon]="faChevronRight"></fa-icon></button>
      </div>
    </div>
    <table class="table" >
      <thead>
        <th hidden>Id</th>
        <th>Fecha</th>
        <th>Cliente</th>        
        <th>Tipo</th>
        <th>Patente</th>
        <th>Producto</th>
        <th>Dias</th>
        <th *ngIf="!is_producer">Productor</th>
        <th>Descargar</th>
      </thead>
      <tbody>
        <tr *ngFor="let item of this.shoppingHistory">
          <td hidden>{{item.id}}</td>
          <td>{{item.validSince | date :'short'}}</td>
          <td>{{item.client.firstName}} {{item.client.lastName}}</td>
          <td>{{item.product.branch.name}}</td>
          <td>{{item.vehicle.licencePlate}}</td>
          <td>{{item.product.short_name}}</td>
          <td style="text-align: center;">{{item.days}}</td>
          <td *ngIf="!is_producer">{{item.producer != null ? item.producer.name : ""}}</td>
          <td>
            <button
              type="button"
              (click)="downloadPolicy(item)"
              class="btn"
            >
              >
            </button>
          </td>
        </tr>
      </tbody>
    </table>
</div>
</div>
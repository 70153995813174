<div class="container text-center">
    <div class="header">
      <h2>Tu solicitud fue generada correctamente</h2>
      <h2>Revisa tu correo para ver los detalles de tu compra.</h2>
    </div>

    <ng-lottie [options]="successLottie"></ng-lottie>

    <div class="nav-buttons">
      <a href="https://seguroporhoy.com" class="btn btn-secondary">
        Volver a Seguro por Hoy
      </a>

      <!-- <button type="button" class="btn btn-secondary" (click)="reset()">
        Nueva solicitud
      </button> -->
    </div>
</div>  
<div class="loading-modal spinner-lg" *ngIf="loading"></div>

<div class="container narrow">
  <div class="header">
    <h2>Qué vehículo vas a usar</h2>
    <app-dots [steps]="stepsCount" [active]="currentStep"></app-dots>
  </div>

  <div class="form-group buttons-list">
    <button
      type="button"
      class="btn"
      *ngFor="let branch of branches"
      (click)="selectBranch(branch)"
    >
      {{ branch.name }}
    </button>
  </div>

  <button
    *ngIf="productor"
    type="button"
    class="btn btn-secondary"
    (click)="prevStep()"
  >
    <fa-icon [icon]="faChevronLeft"></fa-icon> Anterior
  </button>
</div>

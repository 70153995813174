export const environment = {
  production: false,
  //sin / final
  //apiUrl: "http://sxh.api:8000/api/v1",
  apiUrl: "https://api.seguroporhoy.com/api/v1",
  mp: {
    url: 'https://sdk.mercadopago.com/js/v2',
    public_key:'APP_USR-ced8bb8a-07d3-4346-b189-0b1c87c2ce3b'
  }
};

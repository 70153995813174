import { DataService } from 'src/app/shared/services/data.service';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { BaseComponent } from 'src/app/core/components/base-component';
import { forkJoin } from 'rxjs';
import {
  faChevronRight,
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';
import { AuthenticationService } from 'src/app/auth/authentication.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import {Location} from '@angular/common';

@Component({
  selector: 'app-product-step',
  templateUrl: './product-step.component.html',
  styleUrls: ['./../steps.component.scss']
})
export class ProductStepComponent extends BaseComponent implements OnInit{
  loading = false;
  //productsByBranch: any = [];
  productor = false;
  stepsCount = 6;
  currentStep = 3;
  faChevronLeft = faChevronLeft;
  branch: number;
  type: number;
  branchName: string;
  products: any = [];
  branches: any = [];
  client: number = 0;
  paramsObject: any;

  constructor(
    private service: DataService,
    public authenticationService: AuthenticationService,
    private _router: Router,
    private _activeRoute: ActivatedRoute,
    private _location: Location
  ) {
    super();
    this.loadData();

    this.authenticationService
      .loginStatusChange()
      .subscribe(() => this.loadData());
  }

  ngOnInit(): void {
    this._activeRoute.params
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((params: Params) => {
        this.branch = params['branch'] ?? 1;
        this.type = params["type"] ?? 1;
      });

      this._activeRoute.queryParamMap.subscribe((params) => {
        this.paramsObject = { ...params.keys, ...params };
  
        this.client = this.paramsObject.params.client ?? 0;
      });
  }

  loadData() {
    if (this.loading) return;

    this.productor = this.authenticationService.getUser()?.role == 'producer';
    this.loading = true;

    forkJoin(this.service.getProducts(), this.service.getBranches()).subscribe(([productsRes, branchesRes]: any[]) => {
      this.products = productsRes.data;
      this.branches = branchesRes.data;

      this.products = this.productsByBranch()
      this.loading = false;
    });
  }

  selectProduct(product) {
    this._router.navigate(['/form'], {queryParams:{'branch': this.branch,'type':this.type, 'product': product.productId, 'client': this.client}});
  }

  prevStep() {
    this._location.back();
  }

  productsByBranch() {
    return this.products.filter(
      (product) =>
        product.branchId == this.branch &&
        product.type.id == this.type
    );
  }
}

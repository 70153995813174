import {Component, Input, OnChanges, OnInit} from "@angular/core";

@Component({
  selector: "app-dots",
  templateUrl: "./dots.component.html",
  styleUrls: [ "./dots.component.scss" ]
})
export class DotsComponent implements OnInit, OnChanges {
  @Input() steps: number;
  @Input() active: number;
  stepsArray: any = [];

  constructor() {
  }

  ngOnInit(): void {
    this.update();
  }

  ngOnChanges() {
    this.update();
  }

  update() {
    this.stepsArray = [];
    for (let i = 1; i <= this.steps; i++)
      this.stepsArray.push(i);
  }
}

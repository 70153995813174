import { Injectable } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MpService {
  promise: Promise<any> | undefined;
  promise2: Promise<any> | undefined;

  constructor(private http: HttpClient) { }
  
  getPreferenceId(item:any){
    return this.http.post(environment.apiUrl+'/mercado',{'order_id':item});
  }
  getPaymentStatus(paymentId:string){
    return this.http.get(environment.apiUrl+'/payment/'+paymentId);
  }

  formSubmitCompleted(responseDataId: any) {
    this.loadScipts(responseDataId);
  }

  loadScipts(preferenceId) {
    this.promise = new Promise((resolve) => {
      resolve(this.loadSDK());
    }).then(() => {
      setTimeout(() => {
        // configuraciones aquí
        this.promise2 = new Promise((resolve) => {
          // this.step = 'payment';
          resolve(this.putScript(preferenceId));
        }).then(() => {
          setTimeout(() => {
            // configuraciones aquí
            console.log('run Script.');
          }, 2000);
        });
      }, 2000);
    });
  }

  loadSDK() {
    let node = document.createElement('script');
    node.src = environment.mp.url;
    node.type = 'text/javascript';
    node.async = true;
    document.getElementsByTagName('head')[0].appendChild(node);
    return true;
  }

  putScript(preferenceId) {
    let node2 = document.createElement('script');
    let data =
      'const mp = new MercadoPago("' +
      environment.mp.public_key +
      "\",{locale: 'es-AR'});";
    data+="mp.checkout({preference: {id:'"+preferenceId+"'},autoOpen:true, });";
    node2.text = data;
    node2.type = 'text/javascript';
    node2.async = true;
    document.getElementsByTagName('head')[0].appendChild(node2); //*/
    return true;
  }
}


<div *ngIf="step == 'payment'">
    <div class="header">
      <h2>Continua con el pago de tu pedido en MERCADO PAGO</h2>
    </div>
    <div class="cho-container"></div>
  </div>
  <div *ngIf="step == 'success'">
    <div class="header">
        <h2>Listo</h2>
    </div>

    <ng-lottie [options]="successLottie"></ng-lottie>
    <p class="success-text">Tu solicitud fue enviada correctamente.</p>

    <div class="nav-buttons">
        <a href="https://seguroporhoy.com" class="btn btn-secondary">
            Volver a Seguro por Hoy
        </a>

        <button type="button" class="btn btn-secondary" (click)="reset()">
            Nueva solicitud
        </button>
    </div>
</div>
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { StepsComponent } from './steps.component';
import { FormStepComponent } from './form-step/form-step.component';
import { BranchStepComponent } from './branch-step/branch-step.component';
import { TypeStepComponent } from './type-step/type-step.component';
import { ProductStepComponent } from './product-step/product-step.component';
import { PoliciesStepComponent } from './policies-step/policies-step.component';

const routes: Routes = [
  {
    path: '',
    children: [
      // {
      //   path: '',
      //   component: StepsComponent,
      // },
      // {
      //   path: 'policies',
      //   component: PoliciesStepComponent,
      // },
      // {
      //   path: 'branch',
      //   component: BranchStepComponent,
      // },
      // {
      //   path: 'branch/:branch/type',
      //   component: TypeStepComponent,
      // },
      // {
      //   path: 'branch/:branch/type/:type/product',
      //   component: ProductStepComponent,
      // },
      // {
      //   path: 'form',
      //   component: FormStepComponent,
      // },
      // {
      //   path: 'form/:wp',
      //   component: FormStepComponent,
      // },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class StepsRoutingModule {}
